import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, EventEmitter, Output, Input} from '@angular/core';
import { SelectedLocation } from '../../../interfaces/selected-location';

@Component({
  selector: 'app-place-autocomplete',
  templateUrl: './place-autocomplete.component.html',
  styleUrls: ['./place-autocomplete.component.css']
})
export class PlaceAutocompleteComponent implements OnInit {

  @ViewChild('autocomplete') autocompleteElement: ElementRef;
  //@ViewChild('currentLocationItem', {static: false}) currentLocationElement: ElementRef;

  @Input() placeholder: string;
  @Input() icon: string;
  @Output() placeChange: EventEmitter<SelectedLocation> = new EventEmitter();
  showCurrentLocationOption:boolean = false;
  showSpinner = false;
  private _place:SelectedLocation;
  private autocomplete: google.maps.places.Autocomplete;


  constructor() { }

  ngOnInit() { }

  ngAfterViewInit() {
    this.initAutocomplete();
  }

  @Input()
  get place():SelectedLocation {
    return this._place;
  }

  set place(val:SelectedLocation) {
    if (val == this._place) {
      return;
    }
    this._place = val;
    this.placeChange.emit(this._place);
    if (this.autocompleteElement) {
      this.autocompleteElement.nativeElement.value = this._place.name;
    }
  }

  initAutocomplete() {
    let bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds(
      {
        lat: 46.172122,
        lng: 15.624747
      },
      {
        lat: 46.907022,
        lng: 16.520133
      }
    );

    this.autocomplete = new google.maps.places.Autocomplete(
      this.autocompleteElement.nativeElement,
      {
        bounds: bounds,
        strictBounds: true
      }
    );
    this.autocomplete.setFields(['geometry']);
    let component = this;
    this.autocomplete.addListener('place_changed', () => {
      component.place = {
        name: component.autocompleteElement.nativeElement.value,
        location: this.autocomplete.getPlace().geometry.location
      }
      //this.placeSelected.emit(this.autocomplete.getPlace())
    });


    /*let component = this;
    this.autocompleteElement.nativeElement.addEventListener('blur', () => {
      if (component.showCurrentLocationOption) {
        setTimeout(() => component.showCurrentLocationOption = false, 100);
      }
    });*/
  }

  onSearchChange(searchValue:string) {
    this.showCurrentLocationOption = searchValue == '' ? true : false;
  }

  onSearchFocus(searchValue:string) {
    this.showCurrentLocationOption = searchValue == '' ? true : false;
  }

  onSearchBlur() {
    if (this.showCurrentLocationOption) {
      // Hide after some time to allow processing of the potential current location click event
      setTimeout(() => this.showCurrentLocationOption = false, 100);
    }
  }

  selectCurrentLocation() {
    if (navigator.geolocation) {
      this.showSpinner = true;
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.showSpinner = false;
          this.place = {
            name: "Trenutna lokacija",
            location: new google.maps.LatLng(position.coords.latitude, position.coords.longitude)
          };
        },
        (error) => {
          this.showSpinner = false;
          console.log(error);
          alert("Error getting geolocation" + error);
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }

  }

}
