<div *ngFor="let step of steps; last as isLast; first as isFirst" class="row px-3">
  <div class="col-2 px-0 type-container d-flex flex-column align-items-left justify-content-center">
    <img class="location-icon" [src]="'assets/icons/' + (isFirst ? 'icn-origin': 'line-location-marker') + '.svg'">
    <img [src]="'assets/icons/icn-' + step.type.icon + '.svg'">
    <img *ngIf="isLast" class="end-location-icon" [src]="'assets/icons/icn-destination.svg'">
  </div>
  <div class="col-10 px-0">
    <div class="step-data">
      <div class="d-flex d-row justify-content-between">
        <h2>{{ step.start.name }}</h2>
        <h3>{{step.departure | date:'HH:mm'}}</h3>
      </div>
      <div class="data">
        <p><span class="label text-muted">Duration: </span>{{step.duration | prettyDuration }} <span class="dot text-muted">•</span><span class="label text-muted">Distance: </span>{{ step.distance | prettyDistance }} </p>
        <p><span class="label text-muted">CO2 emmission: </span>{{ step.co2}} g</p>
      </div>
      <p>
        <a *ngIf="step.type.name == 'walking' || step.type.name == 'bike_share' || step.type.name == 'car_share'" target="_blank" href="https://www.google.com/maps/dir/?api=1&destination={{step.end.encoded}}&origin={{step.start.encoded}}&travelmode={{step.type.name=='walking' ? 'walking' : 'driving'}}" class="btn navigate-button btn-sm mr-3"><i class="fas fa-location-arrow"></i> Navigate</a>
        <a *ngIf="step.type.name != 'walking' &&  step.type.name != 'bike_share'" target="_blank" href="" class="btn provider-button btn-sm">Contact provider</a>
      </p>
    </div>
    <div *ngIf="isLast" class="d-flex d-row justify-content-between">
      <h2>{{ step.end.name }}</h2>
      <h3>{{step.arrival | date:'HH:mm'}}</h3>
    </div>
  </div>
</div>

