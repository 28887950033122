/* eslint-disable require-jsdoc */
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { Injectable } from "@angular/core";


@Injectable()
export class CacheRouteReuseStrategy implements RouteReuseStrategy {

    private storedRouteHandles: Map<string, DetachedRouteHandle> = new Map<string, DetachedRouteHandle>();
    private saveCache: boolean = false;

    shouldReuseRoute(before: ActivatedRouteSnapshot, curr:  ActivatedRouteSnapshot): boolean {
        if (this.getPath(before) === '' && this.getPath(curr) === 'route/:id') {
            this.saveCache = true;
        } else {
            this.saveCache = false;
        }
        return before.routeConfig === curr.routeConfig;
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        return this.storedRouteHandles.get(this.getPath(route)) as DetachedRouteHandle;
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return this.storedRouteHandles.has(this.getPath(route));
    }

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return this.saveCache;
    }

    store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
        this.storedRouteHandles.set(this.getPath(route), detachedTree);
    }

    private getPath(route: ActivatedRouteSnapshot): string {
        if (route.routeConfig !== null && route.routeConfig.path !== null) {
            return route.routeConfig.path;
        }
        return '';
    }
}
