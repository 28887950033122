import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Route } from 'src/app/interfaces/route';
import { SwiperConfigInterface, SwiperComponent } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-route-list',
  templateUrl: './route-list.component.html',
  styleUrls: ['./route-list.component.scss']
})
export class RouteListComponent implements OnInit {

  @Input() routes:Route[];
  @Output() routeChanged: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('swiper') swiper: SwiperComponent;

  activeIndex: number = 0;

  swiperConfig:SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1.25,
    slidesOffsetBefore: 24,
    slidesOffsetAfter: 24,
    spaceBetween: 24
  }

  constructor() { }

  ngOnInit() {
  }

  transitionEnd() {
    // Fix last slide not geting active attribute
    this.swiper.directiveRef.swiper().snapGrid = [...this.swiper.directiveRef.swiper().slidesGrid];
  }

  routeFocus(index: number) {
    this.routeChanged.emit(index);
    this.activeIndex = index;
  }

}
