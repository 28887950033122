import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainPage } from './pages/main/main.page';
import { SingleRoutePage } from './pages/single-route/single-route.page';
import { DebugPage } from './pages/debug/debug.page';


const routes: Routes = [
  {path: '', component: MainPage},
  {path: 'route/:id', component: SingleRoutePage},
  {path: 'debug', component: DebugPage}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
