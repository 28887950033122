import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prettyDuration'
})
export class PrettyDurationPipe implements PipeTransform {

  transform(value: number): string {
    let s = value;
    if (s < 60) {
      return Math.round(s) + " sec";
    } else if (s < 3600) {
      return Math.round(s/60) + " min";
    } else {
      return Math.floor(s/3600) + " h" + " " + Math.round((s/3600 - Math.floor(s/3600)) * 60) + " min";
    }
  }

}
