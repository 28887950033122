<div class="container px-0">
    <div class="row mx-0 w-100">
      <div class="col px-0">
        <div #map id="map"></div>
        <a [routerLink]="['/']" class="back-button"><i class="fas fa-chevron-left"></i></a>
      </div>
    </div>
    <div class="row mx-0 w-100 px-4 py-5">
      <div class="col px-0">
        <div *ngIf="route" class="basic-info mb-4">
          <div class="d-flex justify-content-between align-items-center">
            <h4>{{route.departure | date:'HH:mm'}} - {{route.arrival | date:'HH:mm'}}</h4>
            <span *ngIf="route.badge" class="greenest-badge">Greenest route</span>
          </div>
          <p class="step-list"><span *ngFor="let step of route.steps; last as isLast"><img [src]="'assets/icons/icn-' + step.type.icon + '.svg'"><img class="arrow" src="assets/icons/icn-arrow-right.svg" *ngIf="!isLast"></span></p>
          <p><span class="label text-muted">Duration: </span>{{route.duration | prettyDuration }} <span class="dot text-muted">•</span><span class="label text-muted">Distance: </span>{{ route.distance | prettyDistance }} </p>
          <div class="progress">
              <div class="progress-bar" role="progressbar" [ngStyle]="{'width.%': route.ecoFriendliness }"></div>
          </div>
          <p class="eco-text">{{ route.ecoFriendliness }}% Eco friendliness</p>
        </div>
        <a id="interest-points-button" target="_blank" href="https://www.expano.si/obisci-pomurje" class="btn btn-block special-button">Zanimivosti na
          destinaciji</a>
        <div class="mt-5 mb-3">
          <app-step-list *ngIf="route" [steps]="route.steps"></app-step-list>
        </div>
      </div>
    </div>
  </div>
