<div class="container h-100 d-flex flex-column px-0" [ngClass]="{'first-search': firstSearch}">
  <div *ngIf="firstSearch" class="animated-icons">
    <img src="assets/icons/icn-bus.svg">
    <img src="assets/icons/icn-train.svg">
    <img src="assets/icons/icn-el-car.svg">
    <img src="assets/icons/icn-walk.svg">
  </div>
  <img class="logo" [src]="'assets/icons/logo-colorful.svg'" />
  <div class="row py-4 search-section mx-0 w-100">
    <div class="col px-4">
      <h1><span class="shake">move</span> Pomurje</h1>
      <h2 *ngIf="firstSearch">environmental friendly travelling</h2>
      <form>
        <div class="row">
          <div class="col locations">
            <div>
              <app-place-autocomplete icon="icn-location-current" placeholder="Choose starting point" [(place)]="origin" (placeChange)="originChanged($event)"></app-place-autocomplete>
              <app-place-autocomplete icon="icn-location" placeholder="Choose destination" [(place)]="destination" (placeChange)="destinationChanged($event)"></app-place-autocomplete>
            </div>
            <div class="switch-button">
              <a (click)="switchOriginAndDestination()"><img src="assets/icons/icn-switch.svg"></a>
            </div>
          </div>
        </div>
        <div *ngIf="!firstSearch" class="row mt-3">
          <div class="col small text-right">
            <a class="toggle-date-and-time" (click)="toggleDateAndTime()">{{ !showDateAndTime ? '+ Add date and time' : '- Hide date and time' }}</a>
          </div>
        </div>
        <div class="row mt-1" *ngIf="showDateAndTime">
          <div class="col date-and-time d-flex flex-row">
            <div class="with-icon">
              <input
                name="date"
                type="date"
                [ngModel]="date | date:'yyyy-MM-dd'"
                [min]="minDate | date:'yyyy-MM-dd'"
                (ngModelChange)="setDate($event)">
              <img class="input-icon" src="assets/icons/icn-calendar.svg">
            </div>
            <div class="with-icon">
              <input
                name="time"
                type="time"
                [ngModel]="time | date:'HH:mm'"
                (ngModelChange)="setTime($event);">
              <img class="input-icon" src="assets/icons/icn-time.svg">
            </div>
          </div>
        </div>
        <div *ngIf="firstSearch" class="row button-section">
          <div class="col-sm mb-2 mt-2">
            <button type="button" class="btn btn-block special-button" (click)="searchForOptimalRoute()">Find the greenest route</button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div [ngClass]="{'d-none': !routes}" class="row mx-0 w-100 map-section">
    <div class="col px-0 h-100">
      <div #map id="map"></div>
      <app-route-list (routeChanged)="routeChanged($event)" [routes]="routes"></app-route-list>
    </div>
  </div>
</div>
