<swiper #swiper [config]="swiperConfig" (indexChange)="routeFocus($event)" (swiperTransitionEnd)="transitionEnd()">
  <div [routerLink]="['route', i]" *ngFor="let route of routes; index as i" class="slide">
    <div class="slide-content">
      <div class="d-flex justify-content-between align-items-center">
        <h4>{{route.departure | date:'HH:mm'}} - {{route.arrival | date:'HH:mm'}}</h4>
        <span *ngIf="route.badge" class="greenest-badge">Greenest route</span>
      </div>
      <p class="step-list"><span *ngFor="let step of route.steps; last as isLast"><img [src]="'assets/icons/icn-' + step.type.icon + '.svg'"><img class="arrow" src="assets/icons/icn-arrow-right.svg" *ngIf="!isLast"></span></p>
      <p><span class="label text-muted">Duration: </span>{{route.duration | prettyDuration }} <span class="dot text-muted">•</span><span class="label text-muted">Distance: </span>{{ route.distance | prettyDistance }} </p>
      <div class="progress">
          <div class="progress-bar" role="progressbar" [ngStyle]="{'width.%': route.ecoFriendliness }"></div>
      </div>
      <p class="eco-text">{{ route.ecoFriendliness }}% Eco friendliness</p>
    </div>
  </div>
</swiper>
