import {Component, OnInit, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import moment from 'moment';
import { SelectedLocation } from '../../interfaces/selected-location';
import { Route } from '../../interfaces/route';
import { RoutesService } from 'src/app/services/routes.service';
import { RouteStep } from 'src/app/interfaces/route-step';
import anime from 'animejs/lib/anime.es.js';

@Component({
  templateUrl: './main.page.html',
  styleUrls: ['./main.page.scss']
})

export class MainPage implements OnInit {

  origin:SelectedLocation;
  destination:SelectedLocation;
  routes:Route[];
  showDateAndTime:boolean = false;
  date:moment.Moment;
  minDate:moment.Moment;
  time:moment.Moment;
  firstSearch:boolean = true;
  @ViewChild('map') mapElement: ElementRef;
  paths: google.maps.Polyline[] = [];
  markers: google.maps.Marker[] = [];

  private map: google.maps.Map;
  private directionsRenderer: google.maps.DirectionsRenderer;


  constructor(private routeService:RoutesService, private changeDetectorRef:ChangeDetectorRef) {}

  ngOnInit() {
    this.date = moment();
    this.minDate = moment();
    this.time = moment();
  }

  ngAfterViewInit() {
    this.initMap();
    this.initDirectionsRenderer();
    this.startAnimation();
  }

  private startAnimation() {
    //let timeline = anime.timeline({loop: false});
    for (let index = 1; index <= 5; index++) {
      anime({
        targets: '.animated-icons img:nth-child(' + index + ')',
        left: ["-24px", '100%'],
        easing: 'easeInOutSine',
        duration: 4000 + Math.random() * 2000,
        loop: true,
        direction: 'alternate',
        delay: 1000 + Math.random() * 1000,
        loopBegin: (anim) => {
          anim.set('.animated-icons img:nth-child(' + index + ')', {
            top: (Math.random() * 90) + "%",
          });
        }
      });
    }
  }



  initMap() {
    let mapOptions: google.maps.MapOptions = {
      zoom: 10,
      center: {lat: 46.653025, lng: 16.189199},
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: false,
    };
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
  }

  initDirectionsRenderer() {
    this.directionsRenderer = new google.maps.DirectionsRenderer();
    this.directionsRenderer.setMap(this.map);
  }

  switchOriginAndDestination() {
    const originalOrigin:SelectedLocation = this.origin;
    this.origin = this.destination;
    this.destination = originalOrigin;
  }

  originChanged(origin:SelectedLocation) {
    if (!this.firstSearch) {
      this.searchForOptimalRoute();
    }
  }

  destinationChanged(destination:SelectedLocation) {
    if (!this.firstSearch) {
      this.searchForOptimalRoute();
    }
  }

  routeChanged(index: number) {
    //this.directionsRenderer.setRouteIndex(index);
    this.drawRoute(index);
  }

  searchForOptimalRoute() {
    if (!this.origin || !this.destination) {
      return;
    }
    this.routeService.searchForOptimalRoutes(this.origin, this.destination, this.time).then(
        (routes:Route[]) => {
          this.routes = routes;
          console.log(routes);

          //this.directionsRenderer.setDirections(this.routeService.getOriginalResult());
          this.firstSearch = false;
          this.changeDetectorRef.detectChanges();
          this.drawRoute(0);

        },
        (error) => alert(error),
    );
  }

  setDate(date:string) {
    this.date = moment(date);
    this.searchForOptimalRoute();
  }

  setTime(time:string) {
    const timeParts:string[] = time.split(':');
    this.time = this.date.set({
      hour: parseInt(timeParts[0]),
      minute: parseInt(timeParts[1])
    });
    this.searchForOptimalRoute();
  }

  toggleDateAndTime() {
    this.showDateAndTime = !this.showDateAndTime;
  }

  private drawRoute(index:number) {
    this.clearMap();
    this.drawStops(index);
    this.drawLines(index);
  }

  private clearMap() {
    this.paths.forEach((path) => path.setMap(null));
    this.paths = [];
    this.markers.forEach((marker) => marker.setMap(null));
    this.markers = [];
  }

  private drawStops(routeIndex:number) {
    let bounds = new google.maps.LatLngBounds();
    // Start marker
    const startMarker: google.maps.Marker = new google.maps.Marker({
      position: this.origin.location,
      map: this.map,
      icon: 'assets/icons/icn-origin.svg'
    });
    this.markers.push(startMarker);
    bounds.extend(startMarker.getPosition());
    // End marker
    const endMarker: google.maps.Marker = new google.maps.Marker({
      position: this.destination.location,
      map: this.map,
      icon: 'assets/icons/icn-destination.svg'
    });
    this.markers.push(endMarker);
    bounds.extend(endMarker.getPosition());
    // Stops
    /*let steps = this.routes[routeIndex].steps;
    for (let index = 1; index < steps.length; index++) {
      const marker: google.maps.Marker = new google.maps.Marker({
        position: steps[index].start.location,
        map: this.map
      });
      this.markers.push(marker);
      bounds.extend(marker.getPosition());
    }*/
    let padding: google.maps.Padding = {
      left: 0,
      right: 0,
      top: 24,
      bottom: 240
    };
    this.map.fitBounds(bounds, padding);
  }

  private drawLines(routeIndex:number) {
    this.routes[routeIndex].steps.forEach((step: RouteStep, index:number) => {
      const path: google.maps.Polyline = new google.maps.Polyline({
        path: google.maps.geometry.encoding.decodePath(step.path),
        geodesic: true,
        strokeColor: '#' + step.type.color,
        strokeOpacity: 1.0,
        strokeWeight: 4,
      });
      path.setMap(this.map);
      this.paths.push(path);
    });

  }

}
