import { Injectable } from '@angular/core';
import { SelectedLocation } from '../interfaces/selected-location';
import { Route } from '../interfaces/route';
import moment from 'moment';


import fakeResult from '../../assets/data/google-response.json';
import { RouteStep } from '../interfaces/route-step';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class RoutesService {

  private directionsService: google.maps.DirectionsService;
  private routes: Route[];

  constructor(private http: HttpClient) {
    this.directionsService = new google.maps.DirectionsService();
  }

  searchForOptimalRoutes(origin:SelectedLocation, destination:SelectedLocation, time?:moment.Moment):Promise<Route[]> {

    let body = {
      origin: origin,
      destination: destination,
      time: time
    };
    return this.http.post<Route[]>('https://us-central1-atob-262908.cloudfunctions.net/search-for-routes/', body).toPromise<Route[]>().then((routes: Route[]) => this.routes = routes);
    /*let options:google.maps.DirectionsRequest = {
      origin: origin.location,
      destination: destination.location,
      travelMode: google.maps.TravelMode.TRANSIT,
      provideRouteAlternatives: true
    };
    if (time) {
      options.transitOptions = {
        departureTime: time.toDate()
      }
    }

    let component = this;
    return new Promise((resolve, reject) => {
      this.directionsService.route(
          options,
          function(response, status) {
            if (status === 'OK') {
              this.routes = component.parseRoutes(response);
              resolve(this.routes);
            } else {
              reject('Directions request failed due to ' + status);
            }
      });
    });*/
  }

  getRoute(index:number):Route | undefined {
    if (this.routes && index < this.routes.length - 1) {
      return this.routes[index];
    }
    return undefined;
  }

}
