import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prettyDistance'
})
export class PrettyDistancePipe implements PipeTransform {
  transform(value:number):string {
    let m = value;
    if (m < 10000) {
      return (m / 1000.0).toFixed(1) + " km";
    } else {
      return (m / 1000.0).toFixed(0) + " km";
    }
  }
}
