import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SwiperModule } from 'ngx-swiper-wrapper';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainPage } from './pages/main/main.page';
import { SingleRoutePage } from './pages/single-route/single-route.page';
import { PlaceAutocompleteComponent } from './pages/main/place-autocomplete/place-autocomplete.component';
import { RouteListComponent } from './pages/main/route-list/route-list.component';
import { PrettyDistancePipe } from './pipes/pretty-distance.pipe';
import { PrettyDurationPipe } from './pipes/pretty-duration.pipe';
import { RouteReuseStrategy } from '@angular/router';
import { CacheRouteReuseStrategy } from './cache-route-reuse.strategy';
import { StepListComponent } from './pages/single-route/step-list/step-list.component';


@NgModule({
  declarations: [
    AppComponent,
    MainPage,
    PlaceAutocompleteComponent,
    RouteListComponent,
    SingleRoutePage,
    PrettyDistancePipe,
    PrettyDurationPipe,
    StepListComponent,
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    SwiperModule,
    HttpClientModule
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: CacheRouteReuseStrategy
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
