import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Route } from 'src/app/interfaces/route';
import moment from 'moment';
import { Graph } from 'src/app/interfaces/graph.js';
import { SelectedLocation } from 'src/app/interfaces/selected-location.js';
import { RouteStep } from 'src/app/interfaces/route-step.js';
import * as d3 from 'd3';
import * as dagreD3 from 'dagre-d3';
import * as dot from 'graphlib-dot';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-debug',
  templateUrl: './debug.page.html',
  styleUrls: ['./debug.page.scss']
})
export class DebugPage implements AfterViewInit {

  private map: google.maps.Map;
  route: Route;

  originPlaceId:string;
  destinationPlaceId:string;

  @ViewChild('map') mapElement: ElementRef;

  constructor(private http:HttpClient) {}

  ngAfterViewInit() {
    this.initMap();
    this.http.get<Graph>('http://localhost:3000/graph').subscribe((graph:Graph) => {
      this.drawMap(graph);
    });
    this.http.get('http://localhost:3000/graph-dot').subscribe((graph) => {
      this.drawGraph(graph);
    });
  }

  private initMap() {
    let mapOptions: google.maps.MapOptions = {
      zoom: 10,
      center: {lat: 46.653025, lng: 16.189199},
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: false,
    };
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
  }

  private drawMap(graph:Graph) {
    this.drawLocations(graph.locations);
    this.drawConnections(graph.connections);
  }

  private drawGraph(dotData) {
    let g = dot.read(dotData.export);

    g.edges().forEach(e => {
      let edge = g.edge(e);
      edge.style = "stroke: #" + edge.edgeColor + "; stroke-width: 2px;";

    });

    // Create the renderer
    var render = new dagreD3.render();

    // Set up an SVG group so that we can translate the final graph.
    var svg = d3.select("svg"),
        inner = svg.append("g"),
        zoom = d3.zoom().on("zoom", function() {
          inner.attr("transform", d3.event.transform);
        });
    svg.call(zoom);

    // Run the renderer. This is what draws the final graph.
    render(inner, g);

    // Center the graph
    /*var xCenterOffset = (svg.attr("width") - g.graph().width) / 2;
    inner.attr("transform", "translate(" + xCenterOffset + ", 20)");
    svg.attr("height", g.graph().height + 40);*/
  }

  private drawLocations(locations: SelectedLocation[]) {
    let bounds = new google.maps.LatLngBounds();
    // Stops
    locations.forEach((location: SelectedLocation) => {
      const marker: google.maps.Marker = new google.maps.Marker({
        position: location.location,
        map: this.map,
        title: location.name
      });
      bounds.extend(marker.getPosition());
    });
    this.map.fitBounds(bounds);
  }

  private drawConnections(connections: RouteStep[]) {
    let component = this;
    const infowindow = new google.maps.InfoWindow({
      content: ''
    });

    // Group by same start and end
    let groupedConnections: { [key: string]: RouteStep[] } = connections.reduce((accumulator: {}, connection: RouteStep) => {
      let group = connection.start.encoded + connection.end.encoded;
      accumulator[group] = accumulator[group] || [];
      accumulator[group].push(connection);
      return accumulator;
    }, {});

    // Iterate grouped connections
    Object.values(groupedConnections).forEach((connections: RouteStep[]) => {
      // Build description
      let description = component.getInfoWindowContent(connections);
      // Create path
      let c = connections[0];
      console.log(c.type);
      const path: google.maps.Polyline = new google.maps.Polyline({
        path: [c.start.location, c.end.location],
        geodesic: true,
        strokeColor: '#' + c.type.color,
        strokeOpacity: 1.0,
        strokeWeight: 4,
        icons: [{
          icon: {path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW},
          offset: '100%'
      }]
      });
      // Add event listener
      google.maps.event.addListener(path, 'click', function(event) {
        infowindow.setContent(description);
        infowindow.setPosition(event.latLng);
        infowindow.open(this.map);
      });
      // Add to map
      path.setMap(this.map);
    });
  }

  private getInfoWindowContent(connections: RouteStep[]) {
    let all = ['<strong>' + connections[0].start.name + " - " + connections[0].end.name + '</strong>'];
    connections.forEach((c: RouteStep) => {
      let single = [
        "<strong>" + moment(c.departure).format("HH:mm") + " - " + moment(c.arrival).format("HH:mm") + "</strong>",
        "<strong>Type: " + c.type.name + "</strong>",
        "Duration: " + (c.duration / 60).toFixed(2) + " min",
        "Distance: " + (c.distance / 1000).toFixed(2) + " km",
      ];
      all.push(single.join("<br>"));
    });
    return all.join("<br>-------------------<br>");
  }
}
