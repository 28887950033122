<input #autocomplete
            type="text"
            placeholder="{{placeholder}}"
            (input)="onSearchChange($event.target.value)"
            (focus)="onSearchFocus($event.target.value)"
            (blur)="onSearchBlur()">
        <div *ngIf="showCurrentLocationOption" class="current-location">
            <a (click)="selectCurrentLocation()"><i class="fas fa-map-marker-alt"></i> Select my current location</a>
        </div>
        <label class="input-icon">
          <img *ngIf="!showSpinner" src="assets/icons/{{icon}}.svg">
          <div *ngIf="showSpinner" class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </label>

<!--<div class="ng-autocomplete">
    <ng-autocomplete
      [data]="data"
      [searchKeyword]="keyword"
      (selected)='selectEvent($event)'
      (inputChanged)='onChangeSearch($event)'
      (inputFocused)='onFocused($event)'
      [itemTemplate]="itemTemplate"
      [notFoundTemplate]="notFoundTemplate">
    </ng-autocomplete>

    <ng-template #itemTemplate let-item>
    <a [innerHTML]="item.name"></a>
    </ng-template>

    <ng-template #notFoundTemplate let-notFound>
    <div [innerHTML]="notFound"></div>
    </ng-template>
</div>-->

<!--<div #currentLocationItem class="pac-item current-location" onmousedown="alert('Yes workingt')">
    <span class="pac-icon pac-icon-marker"></span>
    <span class="pac-item-query">
        <span class="pac-matched">Current location</span>
    </span>
</div>-->
