import { Component, OnInit, Input } from '@angular/core';
import { RouteStep } from 'src/app/interfaces/route-step';

@Component({
  selector: 'app-step-list',
  templateUrl: './step-list.component.html',
  styleUrls: ['./step-list.component.scss']
})
export class StepListComponent implements OnInit {

  @Input() steps:RouteStep[];

  constructor() { }

  ngOnInit(): void {
  }

}
