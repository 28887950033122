<div class="container px-0 mx-0 w-100 h-100">
    <div class="row px-0mx-0  w-100 h-100">
      <div class="col-6">
        <div #map id="map"></div>
      </div>
      <div class="col-6">
        <svg id="svg"></svg>
      </div>
    </div>
</div>
