import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { RoutesService } from 'src/app/services/routes.service';
import { Route } from 'src/app/interfaces/route';
import { RouteStep } from 'src/app/interfaces/route-step';
import { SelectedLocation } from 'src/app/interfaces/selected-location';
import moment from 'moment';

@Component({
  selector: 'app-single-route',
  templateUrl: './single-route.page.html',
  styleUrls: ['./single-route.page.scss']
})
export class SingleRoutePage implements AfterViewInit {

  private map: google.maps.Map;
  private directionsRenderer: google.maps.DirectionsRenderer;
  private routeIndex: number;
  route: Route;

  originPlaceId:string;
  destinationPlaceId:string;

  @ViewChild('map') mapElement: ElementRef;
  @ViewChild('transportPanel') transportPanelElement: ElementRef;

  constructor(private activatedRoute:ActivatedRoute, private routesService:RoutesService) {
    this.routeIndex = parseInt(this.activatedRoute.snapshot.paramMap.get('id'));
  }

  ngAfterViewInit() {
    this.initMap();
    this.showRoute();
  }

  initMap() {
    let mapOptions: google.maps.MapOptions = {
      zoom: 10,
      center: {lat: 46.653025, lng: 16.189199},
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: false,
    };
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
  }

  async showRoute() {
    this.route = this.routesService.getRoute(this.routeIndex);
    if (!this.route) {
      let origin: SelectedLocation = {
        location: new google.maps.LatLng(46.608045, 16.236633),
        name: "Beltinci"
      };
      let destination: SelectedLocation = {
        location: new google.maps.LatLng(46.658547, 16.161233),
        name: "Murska Sobota"
      };
      await this.routesService.searchForOptimalRoutes(origin, destination, moment());
      this.route = this.routesService.getRoute(0);
    }
    this.drawRoute();
  }

  private drawRoute() {
    this.drawStops();
    this.drawLines();
  }

  private drawStops() {
    let bounds = new google.maps.LatLngBounds();
    // Start marker
    const startMarker: google.maps.Marker = new google.maps.Marker({
      position: this.route.steps[0].start.location,
      map: this.map,
      icon: 'assets/icons/icn-origin.svg'
    });
    bounds.extend(startMarker.getPosition());
    // End marker
    const endMarker: google.maps.Marker = new google.maps.Marker({
      position: this.route.steps[this.route.steps.length - 1].end.location,
      map: this.map,
      icon: 'assets/icons/icn-destination.svg'
    });
    bounds.extend(endMarker.getPosition());
    // Stops
    /*let steps = this.routes[routeIndex].steps;
    for (let index = 1; index < steps.length; index++) {
      const marker: google.maps.Marker = new google.maps.Marker({
        position: steps[index].start.location,
        map: this.map
      });
      this.markers.push(marker);
      bounds.extend(marker.getPosition());
    }*/
    this.map.fitBounds(bounds);
  }

  private drawLines() {
    this.route.steps.forEach((step: RouteStep, index:number) => {
      const path: google.maps.Polyline = new google.maps.Polyline({
        path: google.maps.geometry.encoding.decodePath(step.path),
        geodesic: true,
        strokeColor: '#' + step.type.color,
        strokeOpacity: 1.0,
        strokeWeight: 4,
      });
      path.setMap(this.map);
    });

  }

}
